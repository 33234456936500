import React, { Component } from 'react';
import Slider from 'react-slick';

import testimonialbhupeshpatil from '../../../assets/img/team/bhupeshpatil.jpg';
import testimonialankitvaria from '../../../assets/img/testimonial/ankitvaria.jpeg';
import testimonialkalpeshkathane from '../../../assets/img/testimonial/kalpeshkathane.jpg';
import testimonialabhilashnalegaonkar from '../../../assets/img/testimonial/abhilashnalegaonkar.jpg';
import testimonialakankshakate from '../../../assets/img/testimonial/akankshakate.jpg';

const testimonialslide = [
    { img: testimonialbhupeshpatil, name: 'Bhupesh Patil', post: 'CEO & Founder', comment: 'Coming together is the beginning. Staying together is progress. Working together is success.' },
    { img: testimonialankitvaria, name: 'Ankit Varia', post: 'Business Developer', comment: 'The secret of getting ahead is getting started.' },  
    { img: testimonialkalpeshkathane, name: 'Kalpesh Kathane', post: 'Business Developer', comment: 'All our dreams can come true, if we have the courage to pursue them.' },
    { img: testimonialabhilashnalegaonkar, name: 'Abhilash Nalegaonkar', post: 'Business Developer', comment: 'If opportunity doesn’t knock, then build a door.' },
    { img: testimonialakankshakate, name: 'Akanksha Kate', post: 'Business Developer', comment: 'Take risks: if you win, you will be happy; if you lose, you will be wise.' },
];
function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <button className="slick-arrow next-arrow" onClick={onClick}><i className="fal fa-long-arrow-right" /></button>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <button className="slick-arrow prev-arrow" onClick={onClick}><i className="fal fa-long-arrow-left" /></button>
    );
}
class Testimonials extends Component {
    render() {
        const settings = {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 500,
            arrows: true,
            fade: false,
            dots: false,
            swipe: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [{
                breakpoint: 576,
                settings: {
                    arrows: false
                }
            }],
        }
        return (
            <section className="testimonial-section-three no-bg">
                <div className="container">
                    <div className="row justify-content-center no-gutters">
                        <div className="col-lg-10">
                            <Slider className="testimonial-items" id="testimonialSliderThree" {...settings}>
                                {testimonialslide.map((item, i) => (
                                    <div key={i} className="testimonial-item text-center">
                                        <div className="author-thumb">
                                            <img src={item.img} alt="" />
                                        </div>
                                        <div className="content">
                                            <p>
                                                <span className="quote-top">
                                                    <i className="fal fa-quote-left" />
                                                </span>
                                                {item.comment}
                                                <span className="quote-bottom">
                                                    <i className="fal fa-quote-right" />
                                                </span>
                                            </p>
                                        </div>
                                        <div className="author">
                                            <h4>{item.name}</h4>
                                            <span>{item.post}</span>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Testimonials;