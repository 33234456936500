import React, { Component } from "react";
import { Link } from "react-router-dom";

import icon1 from "../../../assets/img/icons/01.png";
import icon2 from "../../../assets/img/icons/02.png";
import icon3 from "../../../assets/img/icons/03.png";
import lines from "../../../assets/img/lines/07.png";

const serviceblock = [
  {
    icon: icon1,
    title: "Software Development",
    text: "Customized Software Solutions, Fast, Productive, and Exceptional. Web, Mobile, Business Systems.",
  },
  {
    icon: icon2,
    title: "Application Development",
    text: "Specialized App Development. Original, User-Friendly. Maximize Effectiveness, Promote Growth.",
  },
  {
    icon: icon3,
    title: "Web Development",
    text: "Web Development Experts. Elegant Sites, Flawless UI & UX. Modern Technology.",
  },
  {
    icon: icon3,
    title: "Training & Outsource",
    text: "Training & Outsourcing Solutions. Enhance Skills and Cost-Effective Services.",
  },
  {
    icon: icon3,
    title: "UI/UX Designing",
    text: "UI/UX Design Experts. Stunning, Intuitive Experiences. Engaging Interfaces.",
  },
];

class Service extends Component {
  render() {
    return (
      <section className="service-section shape-style-one section-gap grey-bg">
        <div className="container">
          {/* Section Title */}
          <div className="section-title text-center both-border mb-30">
            <span className="title-tag">Comany Services</span>
            <h2 className="title">
              “Elevate Your Business with Our Software Development Services”
            </h2>
          </div>
          {/* Services Boxes */}
          <div className="row service-boxes justify-content-center">
            {serviceblock.map((item, i) => (
              <div
                key={i}
                className="col-lg-4 col-md-6 col-sm-8 col-10 col-tiny-12 wow fadeInLeft"
                data-wow-duration="1500ms"
                data-wow-delay="400ms"
              >
                <div className="service-box text-center">
                  <div className="icon">
                    <img src={item.icon} alt="Icon" />
                  </div>
                  <h3>
                    <a href="/services#header">{item.title}</a>
                  </h3>
                  <p>{item.text}</p>
                  <a href="/services#header" className="service-link">
                    <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="dots-line">
          <img src={lines} alt="" />
        </div>
      </section>
    );
  }
}

export default Service;
