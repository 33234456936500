import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'magnific-popup';

import videobg from '../../../assets/img/video-bg/call-img.jpg';
//import playicon from '../../../assets/img/icons/play.svg';
import lines from '../../../assets/img/lines/08.png';

class Video extends Component {
    componentDidMount(){
       
    }
    render() {
        return (
            <section className="video-section bg-img-d section-gap" style={{ backgroundImage: "url(" + videobg + ")"}}>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-7 col-lg-8 col-md-10 order-2 order-md-1">
                            <div className="video-text">
                                <div className="section-title left-border mb-30">
                                    <span className="title-tag">Join winbings family today</span>
                                    <h2 className="title">
                                        Call to join Winbings Family
                                    </h2>
                                </div>
                                <p>
                                    Find the perfect plan for you- 100% satisfaction garanteed.
                                </p>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-lg-4 col-md-2 order-1 order-md-2">
                            <div className="video-btn text-md-center wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                                 <a href="tel:+919730222068" className="main-btn">Call Now</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="line-shape">
                    <img src={lines} alt="Line" />
                </div>
            </section>
        );
    }
}

export default Video;