import React, { Component } from "react";
import { Link } from "react-router-dom";

import img1 from "../../../assets/img/project/winbing-whatsapp.jpg";
import img2 from "../../../assets/img/project/shreeombuildcon.jpg";
import img3 from "../../../assets/img/project/pace.jpg";
import img4 from "../../../assets/img/project/theprotinestudio.png";

const projectblock = [
  // {
  //   img: img1,
  //   title: "Winbings",
  //   text: "Send a WhatsApp direct without adding to your contact list",
  // },
  {
    img: img2,
    title: "Shree Om Buildcon",
    text: "Construction Website Development",
  },
  {
    img: img3,
    title: "Pace Lubricant",
    text: "Ecommerce Website to sell products",
  },
  {
    img: img4,
    title: "The Protein Studio",
    text: "Ecommerce Website to sell products",
  },
];

class Project extends Component {
  render() {
    return (
      <section className="project-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-8">
              {/* Section Title */}
              <div className="section-title left-border">
                <span className="title-tag">Latest Project</span>
                <h2 className="title">We Complate Much More Latest Project</h2>
              </div>
            </div>
            <div className="col-lg-5 col-md-4">
              <div className="view-moore-btn text-md-right mt-30 mt-md-0">
                {/* <Link to="/portfolio" className="main-btn">View More Project</Link> */}
              </div>
            </div>
          </div>
          {/* Project Boxes */}
          <div className="row project-boxes mt-80 justify-content-center">
            {projectblock.map((item, i) => (
              <div key={i} className="col-lg-4 col-sm-6">
                <div className="project-box">
                  <div className="project-thumb">
                    <div
                      className="thumb bg-img-c"
                      style={{ backgroundImage: "url(" + item.img + ")" }}
                    />
                  </div>
                  <div className="project-desc text-center">
                    <h4>{item.title}</h4>
                    <p>{item.text}</p>
                    {/* <Link to="" className="project-link">
                       <i className="fal fa-long-arrow-right" />
                    </Link> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Project;
