import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'magnific-popup';

import videobg from '../../../assets/img/video-bg/video-1.jpg';
import playicon from '../../../assets/img/icons/play.svg';
import line from '../../../assets/img/lines/08.png';

class Video extends Component {
    componentDidMount() {

    }
    render() {
        return (
            <div></div>
            // <section className="video-section bg-img-d section-gap" style={{ backgroundImage: "url(" + videobg + ")" }}>
            //     <div className="container">
            //         <div className="row align-items-center justify-content-between">
            //             <div className="col-xl-7 col-lg-8 col-md-10 order-2 order-md-1">
            //                 <div className="video-text">
            //                     <div className="section-title left-border mb-30">
            //                         <span className="title-tag">Watch Videos</span>
            //                         <h2 className="title">
            //                             Exclusive Video Presentation <br /> About Recent Project
            //                         </h2>
            //                     </div>
            //                     <p>
            //                         Ranim ad minima veniam, quis nostrum exercitationem ullam corpsuscipit
            //                         laboriosam, nisi ut aliquid exea commodi consequa turuis autem
            //                     </p>
            //                     <Link to="/contact" className="main-btn">Need Any Project</Link>
            //                 </div>
            //             </div>
            //             <div className="col-lg-3 col-lg-4 col-md-2 order-1 order-md-2">
            //                 <div className="video-btn text-md-center">
            //                     <Link to="https://www.youtube.com/watch?v=fEErySYqItI" className="play-btn popup-video">
            //                         <img src={playicon} alt="" />
            //                         <i className="fas fa-play" />
            //                     </Link>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            //     <div className="line-shape">
            //         <img src={line} alt="" />
            //     </div>
            // </section>
        );
    }
}

export default Video;