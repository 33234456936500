import React, { Component } from 'react';

import illustration from '../../../assets/img/illustration/contact-us.png';
const axios = require('axios');
class Contact extends Component {
    state = {
        fullName: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        emailStatus: ''
    };
    // when submit btn is clicked
    submitForm = (e) => {
        const {fullName, email,phone,subject, message,emailStatus} = this.state;
        let data =this.state;
        console.log("called here",this.state);
     
        // reset the fields
        this.setState({
            fullName: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
            emailStatus: ''
        });

        axios.post('https://api.winbings.com/api/create', 
            data
          )
          .then(function (response) {
            console.log(response);

          })
          .catch(function (error) {
            console.log(error);
            
          })
          .then(function () {
            // always executed
          });  
        
        e.preventDefault();
    }

   
    // handle the value
    handleCHange = input => e => {
        this.setState({[input]: e.target.value});
    }
    render() {

           function sendEmail(e){
            e.preventDefault();
        
            }
            function refreshPage() {
                window.location.reload(false);
            }

        return (
            <section className="contact-section with-illustration-img grey-bg section-gap">
                <div className="container">
                    <div className="row no-gutters justify-content-end">
                        <div className="col-lg-8">
                            <div className="contact-form">
                                <div className="section-title left-border mb-40">
                                    <span className="title-tag">Drop A Message</span>
                                    <h2 className="title">Don’t Hesitate To Contact Us</h2>
                                </div>
                                 <form onSubmit={this.submitForm}>

                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="input-group mb-30">
                                                <input type="text" placeholder="Your Full Name" value={this.state.fullName} onChange={this.handleCHange('fullName')} />
                                                <span className="icon"><i className="far fa-user-circle" /></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="input-group mb-30">
                                                <input type="email" placeholder="Your Email Address" value={this.state.email} onChange={this.handleCHange('email')} />
                                                <span className="icon"><i className="far fa-envelope-open" /></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="input-group mb-30">
                                                <input type="text" placeholder="Your Phone" value={this.state.phone} onChange={this.handleCHange('phone')} />
                                                <span className="icon"><i className="far fa-phone" /></span>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group select mb-30">
                                            <select name="subject" value={this.state.subject} onChange={this.handleCHange('subject')}>
                                                    <option value="subject">Subject</option>
                                                    <option value="Creative Idea">Creative Idea</option>
                                                    <option value="Relationship">Relationship</option>
                                                    <option value="Business Strategy">Business Strategy</option>
                                                    <option value="Productivity">Productivity</option>
                                                    <option value="It Solutions">It Solutions</option>
                                                    <option value="Product Design">Product Design</option>
                                                </select>
                                            
                                                <span className="icon"><i className="far fa-angle-down" /></span>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group textarea mb-30">
                                                <textarea placeholder="Write Message" value={this.state.message} onChange={this.handleCHange('message')}  />
                                                <span className="icon"><i className="far fa-pencil" /></span>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-4 text-center">
                                        {this.state.emailStatus ? this.state.emailStatus : null}
                                        </div>
                                        <div className="col-12 text-center">
                                            <button type="submit" className="main-btn" value="sendmessage">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="illustration-img">
                    <img src={illustration} alt="" />
                </div>
            </section>
        );
    }
}

export default Contact;