import React, { Component } from "react";
import { Link } from "react-router-dom";

import illustration from "../../../assets/img/illustration/why-we-choose-us.png";
import line1 from "../../../assets/img/lines/03.png";
import line2 from "../../../assets/img/lines/04.png";
class Whyus extends Component {
  render() {
    return (
      <section className="wcu-section box-style">
        <div className="container">
          <div className="wcu-inner">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6">
                <div
                  className="wcu-image text-center text-lg-left wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="400ms"
                >
                  <img src={illustration} alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-10">
                <div className="wcu-text">
                  <div className="section-title left-border mb-40">
                    <span className="title-tag">What We Do</span>
                    <h2 className="title">
                      Winbings: The Smart Choice <br />
                      for Business Growth
                    </h2>
                  </div>
                  <p>
                    Unlocking the Secrets to Commercial Success with Our
                    Software Team
                  </p>
                  <ul className="wcu-list">
                    <li>
                      <i className="far fa-check-circle" /> Experience Developer
                      Team
                    </li>
                    <li>
                      <i className="far fa-check-circle" /> 24/7 Customer
                      Support
                    </li>
                    <li>
                      <i className="far fa-check-circle" /> Safe and Secure
                      Software Development
                    </li>
                  </ul>
                  <Link to="/contact" className="main-btn main-btn-4">
                    Join With Us
                  </Link>
                </div>
              </div>
            </div>
            <img src={line1} alt="shape" className="line-shape-one" />
            <img src={line2} alt="shape" className="line-shape-two" />
          </div>
        </div>
      </section>
    );
  }
}

export default Whyus;
