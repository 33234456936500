import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import VisibilitySensor from "react-visibility-sensor";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

class Skills extends Component {
    render() {
        return (
            <section className="skill-section">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            {/* Skill Text Block */}
                            <div className="skill-text">
                                <div className="section-title mb-40 left-border">
                                    <span className="title-tag">Company Skills</span>
                                    <h2 className="title">We Have Experience <br /> For Business Planning</h2>
                                </div>
                                <p>

                                We at Winbings provide various services like Web Designing, Web Development, Software Development,and Mobile Application Development.Our expert professionals understand and analyse your business needs, project requirements and marketing goals to provide the best solutions. 
                                            </p>
                                <p>
                                We are mainly focused on quality and on time delivery. Our team consists of creative and technical professionals that include web designers, 
                                web developers, software programmers. We provide best solutions on complex architecture to became application more user friendly.
                                Our main aim is to develop in a constant manner and become a leading performer in this competitive global marketplace. 
                                </p>
                                <Link to="/about" className="main-btn">Learn More</Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-10">
                            <div className="piechart-boxes">
                                <div className="chart-box">
                                    <div className="chart">
                                        <VisibilitySensor>
                                            {({ isVisible }) => {
                                                const percentage = isVisible ? 25 : 0;
                                                return (
                                                    <CircularProgressbar
                                                        value={percentage}
                                                        styles={buildStyles({
                                                            strokeLinecap: 'butt',
                                                            pathColor: '#ff4a17',
                                                            trailColor: '#e8e8e8',
                                                            backgroundColor: '#f5f5f5',
                                                        })}
                                                    />
                                                );
                                            }}
                                        </VisibilitySensor>
                                        <i className="flaticon-presentation" />
                                    </div>
                                    <h4 className="title">Business Strategy</h4>
                                </div>
                                <div className="chart-box">
                                    <div className="chart">
                                        <VisibilitySensor>
                                            {({ isVisible }) => {
                                                const percentage = isVisible ? 40 : 0;
                                                return (
                                                    <CircularProgressbar
                                                        value={percentage}
                                                        styles={buildStyles({
                                                            strokeLinecap: 'butt',
                                                            pathColor: '#ff4a17',
                                                            trailColor: '#e8e8e8',
                                                            backgroundColor: '#f5f5f5',
                                                        })}
                                                    />
                                                );
                                            }}
                                        </VisibilitySensor>
                                        <i className="flaticon-money-bags" />
                                    </div>
                                    <h4 className="title">Financial Planing</h4>
                                </div>
                                <div className="chart-box">
                                    <div className="chart">
                                        <VisibilitySensor>
                                            {({ isVisible }) => {
                                                const percentage = isVisible ? 75 : 0;
                                                return (
                                                    <CircularProgressbar
                                                        value={percentage}
                                                        styles={buildStyles({
                                                            strokeLinecap: 'butt',
                                                            pathColor: '#ff4a17',
                                                            trailColor: '#e8e8e8',
                                                            backgroundColor: '#f5f5f5',
                                                        })}
                                                    />
                                                );
                                            }}
                                        </VisibilitySensor>
                                        <i className="flaticon-invest" />
                                    </div>
                                    <h4 className="title">Marketing Startegy</h4>
                                </div>
                                <div className="chart-box">
                                    <div className="chart">
                                        <VisibilitySensor>
                                            {({ isVisible }) => {
                                                const percentage = isVisible ? 80 : 0;
                                                return (
                                                    <CircularProgressbar
                                                        value={percentage}
                                                        styles={buildStyles({
                                                            strokeLinecap: 'butt',
                                                            pathColor: '#ff4a17',
                                                            trailColor: '#e8e8e8',
                                                            backgroundColor: '#f5f5f5',
                                                        })}
                                                    />
                                                );
                                            }}
                                        </VisibilitySensor>
                                        <i className="flaticon-connector" />
                                    </div>
                                    <h4 className="title">Relationship Buildup</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Skills;