import React, { Component } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/img/winbings-logo.png";
import shape1 from "../../assets/img/lines/01.png";
import shape2 from "../../assets/img/lines/02.png";

import img1 from "../../assets/img/instagram/01.png";
import img3 from "../../assets/img/instagram/101.png";

const instapost = [
  {
    img: img1,
    link: "https://www.instagram.com/winbings/",
    logoclass: "fa-instagram",
  },
  {
    img: img1,
    link: "https://www.instagram.com/winbings/",
    logoclass: "fa-facebook",
  },
  {
    img: img3,
    link: "https://www.instagram.com/winbings/",
    logoclass: "fa-twitter",
  },
];
class Footer extends Component {
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <footer>
        <div className="container">
          <div className="footer-widget">
            <div className="row">
              <div className="col-lg-12 col-sm-12 order-1">
                <div className="widget newsletter-widget">
                  <h4 className="widget-title">Subscribe Our Newsletters</h4>
                  <div className="newsletter-form">
                    <form action="#">
                      <input type="email" placeholder="Enter Your Email" />
                      <button type="submit" className="main-btn">
                        Subscribe Now
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 order-2">
                <div className="widget nav-widget">
                  <h4 className="widget-title">Quick Links</h4>
                  <ul>
                    <li>
                      <a href="http://localhost:3000/">Home</a>
                    </li>
                    <li>
                      <a href="http://localhost:3000/services">Services</a>
                    </li>
                    <li>
                      <a href="http://localhost:3000/about">About Us</a>
                    </li>
                    <li>
                      <a href="http://localhost:3000/team">Our Team</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 order-3">
                <div className="row">
                  <div className="col-12">
                    <div className="widget contact-widget">
                      <h4 className="widget-title">Contact Us</h4>
                      <p></p>
                      <ul className="contact-infos">
                        <li>
                          <Link to="tel:+91 9730222068">
                            <i className="far fa-phone" />
                            +91 9730222068
                          </Link>
                        </li>
                        <li>
                          <Link to="mailto:support@gmail.com">
                            <i className="far fa-envelope-open" />
                            contact@winbings.com{" "}
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <i className="far fa-map-marker-alt" /> 3<sup>rd</sup>{" "}
                          Floor, Sundaram Palace , Near sharad Pawar Market
                          Yard, Panchavati, Nashik, Maharashtra 422004
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 order-4">
                <div className="widget site-info-widget">
                  <div className="footer-logo">
                    <img src={logo} alt="" />
                  </div>
                  <p>
                    Achieve Business Growth Through Innovative Concepts by
                    Converting Ideas into Outstanding Software Experiences.
                  </p>
                  <ul className="social-links">
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/Winbings"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://twitter.com/winbings"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/winbings/"
                      >
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg-4 col-sm-6 order-lg-5 order-5">
                <div className="widget insta-feed-widget">
                  <h4 className="widget-title">Social Links</h4>
                  <div className="insta-images">
                    {instapost.map((item, i) => (
                      <div
                        key={i}
                        className="insta-img"
                        style={{ backgroundImage: "url(" + item.img + ")" }}
                      >
                        <a
                          target="_blank"
                          href="https://www.instagram.com/winbings/"
                        >
                          <i className={`fab ${item.logoclass}`} />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="footer-copyright">
            <p className="copyright-text">
              <span></span>
              <span>
                {" "}
                <Link to="#">Winbings©</Link>. All rights reserved.{" "}
              </span>
            </p>
            <Link
              to="#"
              className="back-to-top"
              onClick={() => this.scrollToTop()}
            >
              <i className="far fa-angle-up" />
            </Link>
          </div>
        </div>
        {/* Lines */}
        <img src={shape1} alt="line" className="line-one" />
        <img src={shape2} alt="line" className="line-two" />
      </footer>
    );
  }
}

export default Footer;
