import React, { Component } from "react";
import { Link } from "react-router-dom";

import img1 from "../../../assets/img/project/winbing-whatsapp.jpg";
import img2 from "../../../assets/img/project/shreeombuildcon.jpg";
import img3 from "../../../assets/img/project/pace.jpg";
import img4 from "../../../assets/img/project/theprotinestudio.png";

class Project extends Component {
  render() {
    return (
      <section className="project-section-two section-gap">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Section Title */}
              <div className="section-title text-center">
                <span className="title-tag">Latest Project</span>
                <h2 className="title">
                  We Complate Much More
                  <br />
                  Latest Project
                </h2>
              </div>
            </div>
          </div>
          {/* Project Boxes */}
          <div className="row project-boxes mt-80 masonary-layout align-items-center">
            <div className="col-lg-8 col-md-12 order-1 order-lg-2">
              <div
                className="project-box wide-box wow fadeInLeft"
                data-wow-duration="1500ms"
                data-wow-delay="600ms"
              >
                <div className="project-thumb">
                  <div
                    className="thumb bg-img-c"
                    style={{ backgroundImage: "url(" + img3 + ")" }}
                  />
                </div>
                <div className="project-desc text-center">
                  <h4>
                    <a href="https://pacelubes.com/">Pace Lubricant</a>
                  </h4>
                  <p>Ecommerce Website to sell products</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 order-2 order-lg-1 ">
              <div
                className="project-box wide-box wow fadeInRight"
                data-wow-duration="1500ms"
                data-wow-delay="500ms"
              >
                <div className="project-thumb">
                  <div
                    className="thumb bg-img-c"
                    style={{ backgroundImage: "url(" + img2 + ")" }}
                  />
                </div>
                <div className="project-desc text-center">
                  <h4>
                    <a href="https://shreeombuildcon.com/">Shree Om Buildcon</a>
                  </h4>
                  <p>Construction Website Development</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 order-4 order-lg-3">
              <div
                className="project-box wow fadeInRight"
                data-wow-duration="1500ms"
                data-wow-delay="700ms"
              >
                <div className="project-thumb">
                  <div
                    className="thumb bg-img-c"
                    style={{ backgroundImage: "url(" + img4 + ")" }}
                  />
                </div>
                <div className="project-desc text-center">
                  <h4>
                    <a href="https://theproteinstudio.in/">
                      The Protein Studio
                    </a>
                  </h4>
                  <p>Ecommerce Website to sell products</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 order-3 order-lg-4">
              <div
                className="project-box wow fadeInLeft"
                data-wow-duration="1500ms"
                data-wow-delay="400ms"
              >
                <div className="project-thumb">
                  <div
                    className="thumb bg-img-c"
                    style={{ backgroundImage: "url(" + img1 + ")" }}
                  />
                </div>
                <div className="project-desc text-center">
                  <h4>
                    <a href="https://whatsappdirectmessage.winbings.com/">
                      Winbings
                    </a>
                  </h4>
                  <p>
                    Send a WhatsApp direct without adding to your contact list
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="view-more-btn text-center mt-50">
            <Link to="/contact" className="main-btn main-btn-3">
              Build Your Project
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

export default Project;
