import React, { Component } from "react";
import { Link } from "react-router-dom";

import img1 from "../../../assets/img/feature/awesome-experience.jpg";
import img2 from "../../../assets/img/feature/fast-and-simple.jpg";
import img3 from "../../../assets/img/feature/clean-code.jpg";
import img4 from "../../../assets/img/feature/perfect-design.jpg";
import img5 from "../../../assets/img/feature/seo-friendly.jpg";
import img6 from "../../../assets/img/feature/online-support.jpg";

const featuresblock = [
  {
    img: img1,
    title: "Awesome Experience",
    text: "Enhance your journey by having a genuinely excellent and remarkable experience with our team.    ",
  },
  {
    img: img2,
    title: "Fast and Simple",
    text: "Service that is quick and efficient for simple solutions that accelerate and simplify processes.",
  },
  {
    img: img3,
    title: "Clean Code",
    text: "Clean Code Helps Elevate Your Software. Simple Codes for Superior Solutions and Refined Excellence.",
  },
  {
    img: img4,
    title: "Perfect Design",
    text: "Creating Exceptional Software Development Experiences by Unleashing Perfectly Crafted, Exquisite Design",
  },
  {
    img: img5,
    title: "SEO Friendly",
    text: "We Create Websites That Are Optimized for Search Engines, Helping You Reach Your Target Audience Effectively.    ",
  },
  {
    img: img6,
    title: "24/7 Online Support",
    text: "Our 24/7 Online Support Provides Reliable Assistance to Keep Your Business Thriving Around the Clock.    ",
  },
];
class Features extends Component {
  render() {
    return (
      <section className="feature-section section-gap">
        <div className="container">
          <div className="section-title text-center both-border mb-50">
            <span className="title-tag"> Our Awesome Features </span>
            <h2 className="title">
              “Powerful. Innovative.
              <br />
              Awesome Features for Your Success”
            </h2>
          </div>
          {/* Feature boxes */}
          <div className="feature-boxes row justify-content-center">
            {featuresblock.map((item, i) => (
              <div key={i} className="col-lg-4 col-md-6 col-10 col-tiny-12">
                <div className="feature-box">
                  <div
                    className="feature-bg bg-img-c"
                    style={{ backgroundImage: "url(" + item.img + ")" }}
                  ></div>
                  <div className="feature-desc">
                    <Link to="/services#servicepg" className="feature-link">
                      <i className="fal fa-long-arrow-right" />
                    </Link>
                    <h4>{item.title}</h4>
                    <p>{item.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Features;
