import React, { Component } from "react";
import { Link } from "react-router-dom";

import illustration from "../../../assets/img/illustration/profestional-business-agency.png";
class About extends Component {
  render() {
    return (
      <section className="about-section about-illustration-img section-gap">
        <div className="container">
          <div className="illustration-img">
            <img src={illustration} alt="" />
          </div>
          <div className="row no-gutters justify-content-lg-end justify-content-center">
            <div className="col-lg-6 col-md-10">
              <div className="about-text">
                <div className="section-title left-border mb-40">
                  <span className="title-tag">About Us</span>
                  <h2 className="title">
                    Winbings Software Solutions for Your Business Growth
                  </h2>
                </div>
                <p className="mb-25">
                  At Winbings, we are a professional software development
                  company dedicated to delivering exceptional solutions tailored
                  to meet the unique needs of our clients. With a team of highly
                  skilled and experienced professionals, we are passionate about
                  leveraging technology to drive business growth and success.
                </p>
                <p></p>
                <ul className="about-list">
                  <li>
                    {" "}
                    <i className="far fa-check" />
                    Strategic Solutions for Business Growth
                  </li>
                  <li>
                    {" "}
                    <i className="far fa-check" />
                    Proven Expertise and Results
                  </li>
                  <li>
                    {" "}
                    <i className="far fa-check" />
                    Partnership for Long-Term Success
                  </li>
                  <li>
                    {" "}
                    <i className="far fa-check" />
                    Customized Software and Website Solutions
                  </li>
                  <li>
                    {" "}
                    <i className="far fa-check" />
                    Innovative and Modern Technology
                  </li>
                </ul>
                <Link to="/about" className="main-btn">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
