import React, { Component } from "react";
import { Link } from "react-router-dom";

const Jobcategoryblock = [
  { icon: "flaticon-computer", title: "Software Development" },
  { icon: "flaticon-creative", title: "Mobile App Development" },
  { icon: "flaticon-idea", title: "Web Development" },
  { icon: "flaticon-fingerprint", title: "Training & Outsource" },
  { icon: "flaticon-shield", title: "UI/UX Designing" },
  { icon: "flaticon-training", title: "SEO Services" },
  { icon: "flaticon-analysis", title: "Website Hosting" },
  { icon: "flaticon-piggy-bank", title: "Web Design" },
];

class Jobcategory extends Component {
  render() {
    return (
      <section className="job-category-section section-gap">
        <div className="container">
          <div className="job-categories">
            {Jobcategoryblock.map((item, i) => (
              <div
                key={i}
                className="single-cat wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="1.1s"
              >
                <Link to="/contact">
                  <i className={item.icon} />
                  <span>{item.title}</span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Jobcategory;
