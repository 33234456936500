import React, { Component } from "react";
import { Accordion, Card, Button } from "react-bootstrap";

class faqs extends Component {
  render() {
    return (
      <section className="faq-section section-gap">
        <div className="container">
          {/* FAQ Page Title */}
          <div className="row faq-page-title mb-60 align-items-center">
            <div className="col-lg-6">
              <div className="section-title left-border">
                <span className="title-tag">FAQ</span>
                <h2 className="title">Frequently Asked Questions</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <p>
                winbings team here to trying to understand your problems and
                solve them..
              </p>
            </div>
          </div>
          {/* FAQ LOOP */}
          <Accordion
            defaultActiveKey="0"
            className="faq-loop grey-header"
            id="faqAccordion"
          >
            <Card>
              <Accordion.Collapse eventKey="0" className="collapseparent">
                <Card.Body>
                  As a professional software development agency, Winbings offers
                  a wide range of services. Our expertise includes web and
                  mobile application development, custom software solutions,
                  UI/UX design, e-commerce development, and software maintenance
                  and support. We are committed to delivering high-quality
                  solutions tailored to meet the unique needs of our clients.{" "}
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  What Services Does Winbings Offer as a Software Development
                  Agency?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="1" className="collapseparent">
                <Card.Body>
                  At Winbings, we take pride in our experienced team of software
                  developers. Our team members have a strong background in
                  software development, with expertise in the latest
                  technologies and frameworks. With years of industry
                  experience, we are well-equipped to handle complex projects
                  and deliver high-quality software solutions that meet our
                  clients' requirements.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  How Experienced Is the Team at Winbings in Software
                  Development?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="2" className="collapseparent">
                <Card.Body>
                  Yes, Winbings is proficient in handling custom software
                  development projects. We have a team of skilled developers who
                  specialize in creating tailored solutions based on the unique
                  needs and requirements of our clients. From conceptualization
                  to implementation, we ensure that our custom software
                  development projects are executed with precision and deliver
                  optimal results.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  Can Winbings Handle Custom Software Development Projects?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="3" className="collapseparent">
                <Card.Body>
                  At Winbings, we specialize in a wide range of technologies for
                  software development. Some of our key expertise includes but
                  is not limited to: - Front-end development: HTML, CSS,
                  JavaScript, React, Angular - Back-end development: Node.js,
                  Python, Ruby on Rails, PHP - Database management: MySQL,
                  MongoDB, PostgreSQL - Mobile app development: iOS (Swift,
                  Objective-C), Android (Java, Kotlin) - Cloud platforms: AWS,
                  Azure, Google Cloud Our team stays updated with the latest
                  advancements and best practices in these technologies to
                  deliver cutting-edge software solutions to our clients.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                  What Technologies Does Winbings Specialize in for Software
                  Development?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="4" className="collapseparent">
                <Card.Body>
                  At Winbings, we prioritize the security and quality of our
                  software deliverables. We follow industry-standard security
                  practices, conduct thorough code reviews, and perform rigorous
                  testing at every stage of the development process.
                  Additionally, we adhere to quality assurance protocols and
                  implement robust security measures to protect our clients'
                  data and ensure the reliability and integrity of our software
                  solutions.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                  How Does Winbings Ensure the Security and Quality of Software
                  Deliverables?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="5" className="collapseparent">
                <Card.Body>
                  Yes, Winbings excels in large-scale enterprise software
                  development.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                  Is Winbings Capable of Handling Large-Scale Enterprise
                  Software Development Projects?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="6" className="collapseparent">
                <Card.Body>
                  Winbings ensures timely delivery and efficient project
                  management through robust planning, effective communication,
                  agile methodologies, and dedicated project managers overseeing
                  every aspect of the development process.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="6">
                  How Does Winbings Ensure Timely Delivery and Efficient Project
                  Management?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="7" className="collapseparent">
                <Card.Body>
                  Yes, Winbings provides ongoing support and maintenance for
                  software applications. We offer comprehensive support services
                  to ensure the smooth functioning of the applications, address
                  any issues, and implement necessary updates and enhancements.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="7">
                  Does Winbings Provide Ongoing Support and Maintenance for
                  Software Applications?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="8" className="collapseparent">
                <Card.Body>
                  Winbings has worked with a wide range of industries for
                  software development projects, including but not limited to
                  healthcare, finance, e-commerce, education, logistics, and
                  technology. We have experience in tailoring solutions to meet
                  the unique needs of different industry verticals.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="8">
                  What Industries Has Winbings Worked with for Software
                  Development Projects?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="9" className="collapseparent">
                <Card.Body>
                  Yes, Winbings can help businesses integrate software solutions
                  with their existing systems. We have expertise in seamless
                  integration, ensuring compatibility, data transfer, and smooth
                  operation between different software applications and systems
                  to streamline processes and enhance efficiency.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="9">
                  Can Winbings Help Businesses Integrate Software Solutions with
                  Existing Systems?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            {/* <Card>
              <Accordion.Collapse eventKey="10" className="collapseparent">
                <Card.Body>
                  Yes, we do use Flash when it's required. There are times when
                  Flash animations can be an asset, but there are other times
                  when it is not. For example, we never use Flash to create an
                  entire website or even entire pages. Search engines are not
                  able to read the content of Flash and, as a result, words used
                  in animations are not indexed. It is best to use Flash for
                  small banners, advertisements or demonstrations. Other
                  animations should include some written text in the HTML so
                  that search engines will be able to index the information on
                  the site.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="10">
                  Do you use Flash animation in your website designs?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="11" className="collapseparent">
                <Card.Body>
                  Modern search engines will find your site naturally, through
                  links to your site from other sites. Google especially relies
                  on your incoming links to determine popularity and relevance.
                  For more information on submitting your site and how to
                  promote your site through search engines, read our white paper
                  "Promoting Your Website."
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="11">
                  How do I get my website on search engines, especially Google?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="12" className="collapseparent">
                <Card.Body>
                  If you are not satisfied with the initial design, we will work
                  with you further and come up with another design. Our aim is
                  to design a website that you are completely happy with.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="12">
                  What if I do not like the design?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="13" className="collapseparent">
                <Card.Body>
                  Static Web pages always look the same and the content rarely
                  changes. To make a change, you must create the page yourself
                  or request a Web designer to do it for you. The newly created
                  page and any images must then be uploaded to the Web servers.
                  Dynamic pages are the opposite. They can change every time
                  they are loaded (without anyone having to make those changes),
                  and they can even change their content based on what your
                  website visitors do. The dynamic page can be created to
                  respond to information entered by your site's visitor. They
                  usually store and pull information from an underlying
                  database. Dynamic sites can reduce ongoing maintenance costs
                  if you don't want to change things yourself with a Web page
                  editor. They usually cost more to develop, as they require
                  more complex coding. A content management utility also needs
                  to be developed to help you manage your site. Many clients
                  have a combination of dynamic and static elements in their
                  sites. For instance, they might use dynamic pages for a
                  product catalog where they have to change information or
                  pictures regularly. The "Contact" and "About Us" pages are
                  often left as static pages.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="13">
                  What is the difference between a static site and a dynamic
                  site?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="14" className="collapseparent">
                <Card.Body></Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="14">
                  What is the difference between a static site and a dynamic
                  site?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="15" className="collapseparent">
                <Card.Body>
                  Yes. We have secure servers (SSL) that use the latest
                  encryption methods to ensure that your customers can buy from
                  you website knowing that the information they enter is secure
                  and safe. If you want your customers' payments to be "cleared
                  online," we can interface to PayPal, Authorize.NET, LinkPoint
                  or other third parties. Read more about the e-commerce website
                  design services here.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="15">
                  Can I have an e-commerce system for my website?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="16" className="collapseparent">
                <Card.Body>
                  You can have as many pages as you'd like. In fact, the more
                  the better from a search engine's point of view!
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="16">
                  How many pages can I have on my website?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="17" className="collapseparent">
                <Card.Body>
                  Yes. We can redesign existing sites and retain your
                  organization's style or give you a completely new look.
                  winbings also can provide a free website evaluation. We will
                  show you how your site can take advantage of the latest Web
                  technologies.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="17">
                  Do you redesign existing websites?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="18" className="collapseparent">
                <Card.Body>
                  Our Main Process involves preparing cost analysis, creating
                  roadmaps, defining business goals, key business values,
                  targeting users, implementing main features & prioritising
                  deadlines. We can find you similar projects that are already
                  active on the market to benchmark against to reduce your cost
                  efficiency. We can assist you with creating user personas,
                  mock-ups, user stories, time frame, establishing project
                  status and preparing project estimation. We’ll be happy to
                  outline the project specification, prepare wireframes, details
                  concerning integrations with external services, API
                  documentation, required features list,etc. In terms of project
                  management we prepare server-side setup for your domain, front
                  end, back end, graphic and UX design.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="18">
                  What Are The Main Tasks Or Processes That Are Involved In Your
                  Services?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="19" className="collapseparent">
                <Card.Body>
                  Hopefully the outcome is exactly what you dreamed of 🙂 But
                  apart from delivering a finished product, we are happy to
                  provide you with technical support and project maintenance
                  should you need it. After all, we know our work inside out. Of
                  course if you want to maintain the project by yourself the
                  source code, strategies, plans ,etc. and all technical data is
                  at your disposal, but even in that case, feel free to contact
                  us if you need any help.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="19">
                  What Happens After You Finish My Project?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card> */}
          </Accordion>
          {/* End Faq LOOP */}
        </div>
      </section>
    );
  }
}

export default faqs;
