import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import illustration from '../../../assets/img/illustration/contact-us.png';
const axios = require('axios');

class Content extends Component {
    state = {
        fullName: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        emailStatus: ''
    };
    // when submit btn is clicked
    submitForm = (e) => {
        const {fullName, email,phone,subject, message,emailStatus} = this.state;
        let data =this.state;
        console.log("called here",this.state);
     
        // reset the fields
        this.setState({
            fullName: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
            emailStatus: ''
        });

        axios.post('https://api.winbings.com/api/create', 
            data
          )
          .then(function (response) {
            console.log(response);

          })
          .catch(function (error) {
            console.log(error);
            
          })
          .then(function () {
            // always executed
          });  
        
        e.preventDefault();
    }

   
    // handle the value
    handleCHange = input => e => {
        this.setState({[input]: e.target.value});
    }
    render() {
        return (
            <section className="contact-section contact-page section-gap-top">
                <div className="container">
                    <div className="contact-info">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="illustration-img text-center">
                                    <img src={illustration} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-10 order-1 order-lg-2">
                                <div className="contact-info-content">
                                    <div className="section-title left-border mb-40">
                                        <span className="title-tag">Get In Touch</span>
                                        <h2 className="title">Need Any Help For Software Business &amp; Wesite Building</h2>
                                    </div>
                                    <p>
                                        We provide you a complete website where we can provide features like resposive and attractive design,search engine optimization, at affordable cost.            </p>
                                    <ul>
                                        <li className="phone">
                                            <Link to="tel:+919730222068"><i className="far fa-phone" />+91 9730222068</Link>
                                        </li>
                                        <li><i className="far fa-envelope-open" /><Link to="#">contact@winbings.com</Link></li>
                                        <li><i className="far fa-map-marker-alt" />3<sup>rd</sup> Floor, Sundaram Palace , Near Vasant Pawar Market Yard, Panchavati, Nashik, Maharashtra 422004</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-form grey-bg">
                        <div className="row no-gutters justify-content-center">
                            <div className="col-10">
                                <div className="section-title text-center mb-40">
                                    <h2 className="title">Don’t Hesited To Contact Us</h2>
                                </div>
                                <form onSubmit={this.submitForm}>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="input-group mb-30">
                                                <input type="text" placeholder="Your Full Name" value={this.state.fullName} onChange={this.handleCHange('fullName')} />
                                                <span className="icon"><i className="far fa-user-circle" /></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="input-group mb-30">
                                                <input type="email" placeholder="Your Email Address" value={this.state.email} onChange={this.handleCHange('email')} />
                                                <span className="icon"><i className="far fa-envelope-open" /></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="input-group mb-30">
                                                <input type="text" placeholder="Your Phone" value={this.state.phone} onChange={this.handleCHange('phone')} />
                                                <span className="icon"><i className="far fa-phone" /></span>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group select mb-30">
                                            <select name="subject" value={this.state.subject} onChange={this.handleCHange('subject')}>
                                                    <option value="subject">Subject</option>
                                                    <option value="Creative Idea">Creative Idea</option>
                                                    <option value="Relationship">Relationship</option>
                                                    <option value="Business Strategy">Business Strategy</option>
                                                    <option value="Productivity">Productivity</option>
                                                    <option value="It Solutions">It Solutions</option>
                                                    <option value="Product Design">Product Design</option>
                                                </select>
                                            
                                                <span className="icon"><i className="far fa-angle-down" /></span>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group textarea mb-30">
                                                <textarea placeholder="Write Message" value={this.state.message} onChange={this.handleCHange('message')}  />
                                                <span className="icon"><i className="far fa-pencil" /></span>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-4 text-center">
                                        {this.state.emailStatus ? this.state.emailStatus : null}
                                        </div>
                                        <div className="col-12 text-center">
                                            <button type="submit" className="main-btn" value="sendmessage">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid container-1600">
                    <div className="contact-map">
                        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3748.461493325116!2d73.78708261491617!3d20.031103686547155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5e7c82436d2e1098!2sSundaram%20Palace!5e0!3m2!1sen!2sin!4v1620630618363!5m2!1sen!2sin" style={{ border: 0 }} allowFullScreen aria-hidden="false" tabIndex={0} />
                    </div>
                </div>
            </section>
        );
    }
}

export default Content;