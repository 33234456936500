import React, { Component } from "react";
import { Link } from "react-router-dom";

import illustration from "../../../assets/img/illustration/about-us.png";

class About extends Component {
  render() {
    return (
      <section className="about-section about-illustration-img section-gap">
        <div className="container">
          <div className="illustration-img">
            <img src={illustration} alt="" />
          </div>
          <div className="row no-gutters justify-content-lg-end justify-content-center">
            <div className="col-lg-6 col-md-10">
              <div className="about-text">
                <div className="section-title left-border mb-40">
                  <span className="title-tag">About Us</span>
                  <h2 className="title">
                    Winbings Software Solutions for <br /> Your Business Growth{" "}
                  </h2>
                </div>
                <p className="mb-25">
                  Winbings specialize in developing robust and innovative
                  software applications that empower businesses across various
                  industries. From startups to enterprises, our goal is to
                  provide cutting-edge software solutions that streamline
                  operations, enhance productivity, and drive customer
                  engagement.
                </p>
                <p></p>
                <ul className="about-list">
                  <li>
                    {" "}
                    <i className="far fa-check" /> Expertise and Experience
                  </li>
                  <li>
                    {" "}
                    <i className="far fa-check" /> Client-Centric Approach
                  </li>
                  <li>
                    {" "}
                    <i className="far fa-check" />
                    Quality and Innovation
                  </li>
                  <li>
                    {" "}
                    <i className="far fa-check" /> Collaboration and
                    Communication
                  </li>
                  <li>
                    {" "}
                    <i className="far fa-check" /> Awards Winning Business
                    Comapny
                  </li>
                </ul>
                <Link to="/about" className="main-btn">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
