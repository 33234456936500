import React, { Component } from 'react';
import Slider from 'react-slick';

import testimonial from '../../../assets/img/testimonial/testimonial-image-banner.png';
import testimonialbhupeshpatil from '../../../assets/img/team/bhupeshpatil.jpg';
import testimonialankitvaria from '../../../assets/img/testimonial/ankitvaria.jpeg';
import testimonialkalpeshkathane from '../../../assets/img/testimonial/kalpeshkathane.jpg';
import testimonialabhilashnalegaonkar from '../../../assets/img/testimonial/abhilashnalegaonkar.jpg';
import testimonialakankshakate from '../../../assets/img/testimonial/akankshakate.jpg';

const testimonialslide = [
    { img: testimonialbhupeshpatil, name: 'Bhupesh Patil', post: 'CEO & Founder', comment: 'Coming together is the beginning. Staying together is progress. Working together is success.' },
    { img: testimonialankitvaria, name: 'Ankit Varia', post: 'Business Developer', comment: 'The secret of getting ahead is getting started.' },  
    { img: testimonialkalpeshkathane, name: 'Kalpesh Kathane', post: 'Business Developer', comment: 'All our dreams can come true, if we have the courage to pursue them.' },
    { img: testimonialabhilashnalegaonkar, name: 'Abhilash Nalegaonkar', post: 'Business Developer', comment: 'If opportunity doesn’t knock, then build a door.' },
    { img: testimonialakankshakate, name: 'Akanksha Kate', post: 'Business Developer', comment: 'Take risks: if you win, you will be happy; if you lose, you will be wise.' },
]
class Testimonials extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        const settings = {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 500,
            arrows: true,
            fade: false,
            dots: false,
            swipe: true,
        }
        return (
            <section className="testimonial-section grey-bg">
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-start">
                        <div className="col-lg-6 col-md-10 offset-lg-5">
                            <div className="section-title left-border">
                                <span className="title-tag">Meet Our Advisors</span>
                                <h2 className="title">We Have An Exclusive <br /> Team Member</h2>
                            </div>
                            <Slider className="testimonial-items" ref={c => (this.slider = c)} id="testimonialSliderOne" {...settings}>
                                {testimonialslide.map((item, i) => (
                                    <div key={i} className="testimonial-item">
                                        <div className="content">
                                            <p>
                                                <span className="quote-top">
                                                    <i className="fas fa-quote-left" />
                                                </span>
                                                {item.comment}
                                                <span className="quote-bottom">
                                                    <i className="fas fa-quote-right" />
                                                </span>
                                            </p>
                                        </div>
                                        <div className="author">
                                            <div className="thumb">
                                                <img src={item.img} alt="img" />
                                            </div>
                                            <div className="desc">
                                                <h4>{item.name}</h4>
                                                <span>{item.post}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                            <div className="testimonial-arrows row">
                                <div className="col-12 order-1"><button className="slick-arrow next-arrow" onClick={this.next}><i className="fal fa-long-arrow-right" /></button></div>
                                <div className="col-12 order-2"><button className="slick-arrow prev-arrow" onClick={this.previous}><i className="fal fa-long-arrow-left" /></button></div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Testimonials img */}
                <div className="testimonial-img">
                    <img src={testimonial} alt="" />
                </div>
            </section>
        );
    }
}

export default Testimonials;