import React, { Component } from "react";
import { Link } from "react-router-dom";

const joblisting = [
  {
    jobtitle: "Software Development:",
    text: "Your trusted partner for professional software development. We create innovative and robust solutions that help businesses thrive. Experience excellence in software development with Winbings.",
    btntext: "apply now",
  },
  {
    jobtitle: "Mobile App Development:",
    text: "Winbings is a professional mobile app development company. Our expert team creates custom mobile apps with a focus on user experience, performance, and security. We deliver innovative solutions that help businesses thrive in the digital era.",
    btntext: "apply now",
  },
  {
    jobtitle: "Web Development:",
    text: "Winbings is a professional web development company. We design and develop stunning websites that are user-friendly, responsive, and optimized for search engines. Trust us for your online success.",
    btntext: "apply now",
  },
  {
    jobtitle: "Training & Outsource:",
    text: "Unlock your team's potential with Winbings' professional training and outsourcing services. Enhance skills, maximize productivity, and streamline operations with our tailored solutions. ",
    btntext: "apply now",
  },
  {
    jobtitle: "UI/UX Designing:",
    text: "Winbings offers expert UI/UX design services to create engaging and intuitive user experiences. We combine creativity, user research, and industry best practices to deliver visually stunning and user-friendly designs for your digital products.",
    btntext: "apply now",
  },
  {
    jobtitle: "SEO Services:",
    text: "Boost your online visibility, drive targeted traffic, and outrank your competition with Winnings' professional SEO services. Enhance your website's performance and increase your organic search rankings with our strategic optimization techniques.",
    btntext: "apply now",
  },
  {
    jobtitle: "Website Hosting:",
    text: "Winbings offers professional website hosting services that guarantee reliability, security, and lightning-fast performance. Our scalable infrastructure ensures seamless scalability as your business grows. With expert support available 24/7, your website is in safe hands.",
    btntext: "apply now",
  },
  {
    jobtitle: "Web Design:",
    text: "Winbings offers professional web design services tailored to your business needs. Our team creates visually appealing and user-friendly websites that drive engagement and deliver exceptional online experiences for your customers.",
    btntext: "apply now",
  },
];

class Joblist extends Component {
  render() {
    return (
      <section className="job-list-section section-gap grey-bg">
        <div className="container">
          <div className="job-lists">
            {joblisting.map((item, i) => (
              <div key={i} className="single-job">
                <h4 className="title">{item.jobtitle}</h4>
                <p>{item.text}</p>
                <Link to="/contact" className="apply-btn">
                  {item.btntext} <i className="far fa-long-arrow-right" />
                </Link>
              </div>
            ))}
          </div>
          <div className="view-btn text-center mt-60">
            {/* <Link to="/service" className="main-btn">View All Jobs</Link> */}
          </div>
        </div>
      </section>
    );
  }
}

export default Joblist;
