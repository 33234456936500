import React, { Component } from "react";
import { Link } from "react-router-dom";

import img1 from "../../../assets/img/team/bhupesh_patil.jpeg";
import img2 from "../../../assets/img/team/swapnilKangalarkar.jpg";
import img3 from "../../../assets/img/team/ankitvaria.jpeg";
import img4 from "../../../assets/img/team/kalpeshkathane.jpg";
import img5 from "../../../assets/img/team/abhilashnalegaonkar.jpg";
import img6 from "../../../assets/img/team/akankshakate.jpg";
import img7 from "../../../assets/img/team/dikshantgoyal.jpg";
const teamblock = [
  {
    id: 1,
    img: img1,
    name: "Bhupesh Patil",
    post: "CEO & Founder",
    slug: "bhupesh_patil",
  },
  {
    id: 2,
    img: img2,
    name: "Swapnil Kangalarkar",
    post: "Business Developer",
    slug: "swapnil",
  },
  {
    id: 3,
    img: img3,
    name: "Ankit varia",
    post: "Business Developer",
    slug: "ankit",
  },
  {
    id: 4,
    img: img4,
    name: "Kalpesh Kathane",
    post: "Business Developer",
    slug: "kalpesh",
  },
  {
    id: 5,
    img: img5,
    name: "Abhilash Nalegaonkar",
    post: "Business Developer",
    slug: "abhilash",
  },
  {
    id: 6,
    img: img6,
    name: "Akanksha Kate",
    post: "Business Developer",
    slug: "akanksha",
  },
  {
    id: 7,
    img: img7,
    name: "Dikshant Goyal",
    post: "Business Developer",
    slug: "dikshant",
  },
  // { id:8, img: img8, name: 'Vaishnavi Misal', post: 'Business Developer' },
];
const description = [
  {
    slug: "bhupesh_patil",
    data: "Winbings has established itself as a trailblazer in the software development industry, consistently delivering outstanding results and shaping the future of technology.",
  },
  {
    slug: "swapnil",
    data: "In an ever-evolving landscape, Swapnil Kangalarkar stands as a driving force behind Winbings expansion, forging strategic pathways, fostering innovation, and propelling the software development business toward new horizons.",
  },
  {
    slug: "ankit",
    data: "A true advocate for delivering exceptional client experiences, Ankit varia embodies a commitment to excellence that permeates every aspect of Winbings operations. Their dedication to quality drives the software development journey.",
  },
  {
    slug: "kalpesh",
    data: " At the heart of Kalpesh Kathane's role is building and nurturing relationships. With a knack for forging strong connections, they collaborate seamlessly with clients, stakeholders, and partners, creating synergistic alliances that fuel innovation and success.",
  },
  {
    slug: "abhilash",
    data: "With an inherent knack for understanding client requirements, Abhilash Nalegaonkar ensures that our software solutions not only meet but exceed expectations. Their personalized approach to client relations fosters long-term partnerships built on trust and mutual success.",
  },
  {
    slug: "akanksha",
    data: "Under Akanksha Kate's leadership, our business development efforts have consistently outpaced industry standards. Their strategic vision has led to the identification of new market opportunities and the successful execution of initiatives that drive revenue growth.",
  },
  {
    slug: "dikshant",
    data: "Dikshant Goyal's commitment to understanding clients' unique needs and tailoring solutions accordingly has been instrumental in our reputation for client satisfaction. Their client-centric approach ensures that every engagement is a testament to our dedication to delivering value and exceeding expectations.",
  },
];
class Memberdetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamArray: [],
      teamDescArray: [],
    };
  }

  componentDidMount() {
    console.log(window.location.href);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    console.log(params.get("name"));
    //setId(params.get("name"));
    this.viewCategoryData(params.get("name"));
  }

  async viewCategoryData(name) {
    this.setState({
      teamArray: await teamblock.filter(function (item) {
        return item.slug == name;
      }),
      teamDescArray: await description.filter(function (data) {
        return data.slug == name;
      }),
    });
    console.log(this.state.teamDescArray);
  }

  render() {
    return (
      <section className="member-details-wrapper section-gap-top">
        <div className="container">
          <div className="member-details">
            <div className="member-picture-wrap">
              {this.state.teamArray.map((item, i) => (
                <div className="member-picture">
                  <img src={item.img} alt="" />
                </div>
              ))}
            </div>
            {this.state.teamArray.map((item, i) => (
              <div className="member-desc">
                <h3 className="name">{item.name}</h3>
                <span className="pro">{item.post}</span>
                {this.state.teamDescArray.map((item1) => (
                  <p>{item1.data}</p>
                ))}
              </div>
            ))}
            <div className="member-contact-info">
              <h3 className="title">Get In Touch</h3>
              <span className="title-tag">Contact Me</span>
              <ul className="contact-info">
                <li>
                  <i className="far fa-phone" /> <strong>+91 9730222068</strong>
                </li>
                <li>
                  <Link to="#">
                    <i className="far fa-envelope" /> contact@winbings.com
                  </Link>
                </li>
                <li>
                  <i className="far fa-map-marker-alt" /> 3<sup>rd</sup> Floor,
                  Sundaram Palace, Near sharad Pawar Market Yard, Panchavati,
                  Nashik, Maharashtra 422004
                </li>
              </ul>
              <ul className="social-links">
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/Winbings"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/winbings"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/winbings/"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Memberdetail;
