import React, { Component } from "react";
import { Tab, Nav } from "react-bootstrap";

import img from "../../../assets/img/tab-block.jpg";
import logo1 from "../../../assets/img/illustration/missionvision.png";
import logo2 from "../../../assets/img/illustration/history.png";
import logo3 from "../../../assets/img/illustration/businessgoals.png";
import logo4 from "../../../assets/img/illustration/teammember.png";

const missiontab = [
  {
    icon: "fas fa-check",
    text: "Prioritizing the latest tech stack for our developers.",
  },
  {
    icon: "fas fa-check",
    text: "Custom designs for all your business assets.",
  },
  {
    icon: "fas fa-check",
    text: "Creative product development with top quality.",
  },
];
const historytab = [
  {
    icon: "fas fa-check",
    text: "Cutting-edge Product Development and Web Applications",
  },
  { icon: "fas fa-check", text: "Innovative Mobile and Desktop Applications" },
  {
    icon: "fas fa-check",
    text: "Expert Developers and Round-the-Clock Support",
  },
];
const businessgoalstab = [
  {
    icon: "fas fa-check",
    text: "Emphasizing Cutting-Edge Tech for Website Developers",
  },
  {
    icon: "fas fa-check",
    text: "Delivering Top-Notch Software at Affordable Rates",
  },
  { icon: "fas fa-check", text: "Accelerating Customer Business Growth" },
];
const teammembertab = [
  { icon: "fas fa-check", text: "Expert & Supportive Team" },
  { icon: "fas fa-check", text: "Innovative Team Players" },
  { icon: "fas fa-check", text: "Team Passion Fuels Business Growth" },
];

class Advancedtab extends Component {
  render() {
    return (
      <section className="advanced-tab section-gap">
        <div className="container">
          {/* Tabs Buttons */}
          <div className="tab-buttons">
            <Tab.Container defaultActiveKey="mission">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="mission">
                    Our Mission &amp; Vision
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="history">Company History</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="businessgoals">Business Goals</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="teammember">One Team</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="mission">
                  <div className="tab-text-block left-image with-left-circle">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-lg-6 col-md-10">
                        <div className="block-image">
                          <img src={logo1} alt="Logo" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-10">
                        <div className="block-text">
                          <h2 className="title">Mission & Vision</h2>
                          <p>
                            Winnings' mission is to fuel the business growth of
                            our clients through innovative design, application
                            development, and market-leading solutions. Our focus
                            is on delivering high-quality, value-driven
                            solutions that provide a competitive edge in the
                            digital landscape, regardless of industry.
                          </p>
                          {/* <p>Every business must become digital, doesn’t matter which industry you belong to.</p> */}
                          <ul>
                            {missiontab.map((item, i) => (
                              <li key={i}>
                                <i className={item.icon} />
                                {item.text}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="history">
                  <div className="tab-text-block right-image with-right-circle">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-lg-6 col-md-10 order-2 order-lg-1">
                        <div className="block-text">
                          <h2 className="title">History</h2>
                          <p>
                            Winbings embarked on a capital-raising journey to
                            expedite its growth trajectory. Since then, it has
                            emerged as a market leader in digitizing small
                            businesses. The company's launch of highly acclaimed
                            mobile and desktop applications has garnered
                            significant success and recognition in the industry.
                          </p>
                          <ul>
                            {historytab.map((item, i) => (
                              <li key={i}>
                                <i className={item.icon} />
                                {item.text}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-10 order-1 order-lg-2">
                        <div className="block-image">
                          <img src={logo2} alt="Logo" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="businessgoals">
                  <div className="tab-text-block left-image with-left-circle">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-lg-6 col-md-10">
                        <div className="block-image">
                          <img src={logo3} alt="" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-10">
                        <div className="block-text">
                          <h2 className="title">Business Grow </h2>
                          <p>
                            It all began with a simple idea: the need for small
                            businesses to thrive. While they may often go
                            unnoticed, they form the backbone of every economy.
                            At Winbings, we are passionate about empowering
                            their stories. We strive to help them surpass
                            expectations by providing top-notch technology and
                            tools for their growth.
                          </p>
                          <ul>
                            {businessgoalstab.map((item, i) => (
                              <li key={i}>
                                <i className={item.icon} />
                                {item.text}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="teammember">
                  <div className="tab-text-block right-image with-right-circle">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-lg-6 col-md-10 order-2 order-lg-1">
                        <div className="block-text">
                          <h2 className="title">One Team</h2>
                          <p>
                            We foster a unified team mindset where every
                            individual is valued. Designations and titles hold
                            no significance compared to the strength of our
                            collective collaboration. We stand together as a
                            team, united and committed to our shared goals.
                          </p>
                          <ul>
                            {teammembertab.map((item, i) => (
                              <li key={i}>
                                <i className={item.icon} />
                                {item.text}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-10 order-1 order-lg-2">
                        <div className="block-image">
                          <img src={logo4} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </section>
    );
  }
}

export default Advancedtab;
