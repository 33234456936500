import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'magnific-popup';

//import videobg from '../../../assets/img/video-bg/video-2.jpg';
//import line1 from '../../../assets/img/lines/11.png';
//import line2 from '../../../assets/img/lines/12.png';

class Video extends Component {
   
    render() {
        return (
            <section>
            </section>
        );
    }
}

export default Video;