import React, { Component } from "react";
import { Link } from "react-router-dom";

import img1 from "../../../assets/img/team/bhupesh_patil.jpeg";
import img2 from "../../../assets/img/team/swapnilKangalarkar.jpg";
import img3 from "../../../assets/img/team/ankitvaria.jpeg";
import img4 from "../../../assets/img/team/kalpeshkathane.jpg";
import img5 from "../../../assets/img/team/abhilashnalegaonkar.jpg";
import img6 from "../../../assets/img/team/akankshakate.jpg";
import img7 from "../../../assets/img/team/dikshantgoyal.jpg";
// import img8 from "../../../assets/img/team/vaishnavimisal.jpg";

const teamblock = [
  {
    id: 1,
    img: img1,
    name: "Bhupesh Patil",
    post: "CEO & Founder",
    slug: "bhupesh_patil",
  },
  {
    id: 2,
    img: img2,
    name: "Swapnil Kangalarkar",
    post: "Business Developer",
    slug: "swapnil",
  },
  {
    id: 3,
    img: img3,
    name: "Ankit varia",
    post: "Business Developer",
    slug: "ankit",
  },
  {
    id: 4,
    img: img4,
    name: "Kalpesh Kathane",
    post: "Business Developer",
    slug: "kalpesh",
  },
  {
    id: 5,
    img: img5,
    name: "Abhilash Nalegaonkar",
    post: "Business Developer",
    slug: "abhilash",
  },
  {
    id: 6,
    img: img6,
    name: "Akanksha Kate",
    post: "Business Developer",
    slug: "akanksha",
  },
  {
    id: 7,
    img: img7,
    name: "Dikshant Goyal",
    post: "Business Developer",
    slug: "dikshant",
  },
  // { id:8, img: img8, name: 'Vaishnavi Misal', post: 'Business Developer' },
];
class Teamblock extends Component {
  render() {
    return (
      <section className="team-section section-gap">
        <div className="container">
          {/* Section Title */}
          <div className="section-title mb-40 both-border text-center">
            <span className="title-tag">Meet Our Advisors</span>
            <h2 className="title">
              We Have An Exclusive <br /> Team Member
            </h2>
          </div>
          {/* Team Boxes */}
          <div className="row team-members justify-content-center">
            {teamblock.map((item, i) => (
              <div key={i} className="col-lg-3 col-md-4 col-6 col-tiny-12">
                <div className="team-member">
                  <div className="member-picture-wrap">
                    <div className="member-picture">
                      <img src={item.img} alt="" />
                      <div className="social-icons">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.facebook.com/Winbings"
                        >
                          <i className="fab fa-facebook-f" />
                        </a>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://twitter.com/winbings"
                        >
                          <i className="fab fa-twitter" />
                        </a>

                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.instagram.com/winbings/"
                        >
                          <i className="fab fa-instagram" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="member-desc">
                    <h3 className="name">
                      <Link to={"/teamdetail?name=" + item.slug}>
                        {item.name}
                      </Link>
                    </h3>
                    <span className="pro">{item.post}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Teamblock;
